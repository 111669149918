import React from 'react'; 

function App() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
	  <h1> Hola DO!</h1>
	  <p> Esta es una aplicacion React minima 3</p>
    </div>
  );
}

export default App;
